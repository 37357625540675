<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>仓储管理</el-breadcrumb-item>
      <el-breadcrumb-item>公共库存状态</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 内容开始 -->
    <el-card class="box-card">
      <div class="top">
        <div class="tops">
          <span>货物编号 :</span>
          <el-autocomplete
            clearable
            :trigger-on-focus="false"
            placeholder="请输入货物编号"
            v-model="shaibian"
            :fetch-suggestions="bianvalidateCounts"
            value-key="goods_no"
            @select="bianhandleSelect($event, '货物编号')"
            @clear="chu"
          ></el-autocomplete>
        </div>
        <div class="tops">
          <span>货物名称 :</span>
          <el-autocomplete
            clearable
            :trigger-on-focus="false"
            placeholder="请输入货物名称"
            v-model="shainame"
            :fetch-suggestions="huowuvalidateCounts"
            value-key="name"
            @select="handleSelect($event, '货物名称')"
            @clear="qingchu"
          ></el-autocomplete>
        </div>
        <div class="tops">
          <span>货物类别 :</span>
          <el-cascader v-model="type" :options="leilist" :show-all-levels="false" @change="leichenge" :props="propss" clearable @clear="qing"></el-cascader>
        </div>
      </div>
      <!-- 表格开始 -->
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column prop="goods_no" label="货物编号"></el-table-column>
        <el-table-column prop="goods_name" label="货物名称"></el-table-column>
        <el-table-column prop="type_title" label="货物类别"></el-table-column>
        <el-table-column prop="unit_name" label="货物单位" align="center"></el-table-column>
        <el-table-column prop="goods_num" label="货物数量" align="center"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="editfun(scope.row.id)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
    </el-card>

    <!-- 弹窗 -->
    <el-dialog title="配件库存明细" :visible.sync="dialogVisible" width="50%" :before-close="handleClose" center>
      <div class="tan">
        <div class="let">货物名称</div>
        <div class="el-select"><el-input v-model="infolist.goods_name" disabled></el-input></div>
      </div>
      <div class="tan">
        <div class="let">货物编号</div>
        <div class="el-select"><el-input v-model="infolist.goods_no" disabled></el-input></div>
      </div>
      <div class="tan">
        <div class="let">货物描述</div>
        <div class="el-select"><el-input v-model="infolist.goods_desc" disabled></el-input></div>
      </div>
      <!-- <el-button type="primary" @click="tiao">申请调库</el-button> -->
      <el-table :data="huoxianglist" stripe style="width: 80%">
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="house_name" label="仓库名称"></el-table-column>
        <el-table-column prop="unit" label="货物单位"></el-table-column>
        <el-table-column prop="goods_num" label="库存数量"></el-table-column>
        <el-table-column prop="available_num" label="可出库存"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Fenye from '../../../components/Fenye.vue'
import { Shopsou, Huoshu, Getpeilist, Huopixiang } from '../../../api/store'
export default {
  components: {
    Fenye
  },
  data() {
    return {
      shaibian: '',
      type: '',
      shainame: '',
      goods_type: '',
      infolist: {},
      huoxianglist: [],        // 货物详情
      huolist: [],             // 货物模糊搜索数据
      leilist: [],             // 货物类型
      bianlist: [],            // 货品编号
      dialogVisible: false,
      propss: {
        label: 'title',
        children: 'list',
        value: 'id'
      },
      pageData: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 10,
        // 共有多少条
        count: 0
      },
      tableData: []
    }
  },
  mounted() {
    this.Huowulei() // 货物类型筛选
    this.list()
  },
  methods: {
    // 跳转
    // tiao() {
    //   this.$router.push({
    //     path: `buyer`
    //   })
    // },
    // 弹窗默认关闭
    handleClose() {
      this.dialogVisible = false
    },

    // 名称筛选清除
    qingchu() {
      this.shainame = ''
      this.list()
    },

    // 类型清楚
    qing() {
      this.goods_type = ''
      this.type = ''
      this.list()
    },

    // 编号筛选清除
    chu() {
      this.shaibian = ''
      this.list()
    },
    bianzhi(newSize) {
      // 分页值变化
      this.pageData.size = newSize
      this.list()
    },
    bianye(newPape) {
      // 分页页数变化
      this.pageData.page = newPape
      this.list()
    },

    // 详情按钮
    editfun(id) {
      // console.log(id)
      this.dialogVisible = true
      this.Huopicixiang(id)
    },

    leichenge(e) {
      this.goods_type = e[e.length - 1] === undefined ? 0 : e[e.length - 1]
      this.list()
    },

    // 货品搜索选中
    handleSelect(e) {
      this.list()
    },

    bianhandleSelect(e) {
      this.list()
    },

    // 搜商品的数据
    huowuvalidateCounts(queryString, cb) {
      this.Drops(queryString, cb)
    },
    bianvalidateCounts(queryString, cb) {
      this.Brops(queryString, cb)
    },

    // 商品搜索
    async Drops(name, cd) {
      const { data } = await Shopsou({ name })
      this.huolist = data.data
      cd(data.data)
    },

    async list() {
      const { data } = await Getpeilist({ is_public: 1, ...this.pageData, name: this.shainame, goods_no: this.shaibian, goods_type: this.goods_type })
      this.tableData = data.data.data
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
    },

    // 商品编号搜索
    async Brops(goods_no, cd) {
      const { data } = await Getpeilist({ goods_no })
      this.bianlist = data.data.data
      cd(data.data.data)
    },

    // 仓库类型
    async Huowulei() {
      const { data } = await Huoshu()
      this.leilist = data.data
    },

    // 货品批次详情
    async Huopicixiang(id) {
      const { data } = await Huopixiang({ goods_id: id })
      this.huoxianglist = data.data.items
      this.infolist = data.data.info
    }
  }
}
</script>

<style>
.tops {
  margin-left: 30px;
}
.top {
  display: flex;
  align-items: center;
}
.let {
  width: 70px;
  margin-left: 15px;
  margin-right: 10px;
}
.tan {
  display: flex;
  align-items: center;
}
</style>